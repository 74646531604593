export const P0_GCLOUD_ORGANIZATION_ID = "698375260981";

export const GCLOUD_BOUNDARY_TEST_ROLE = "browser";

export const GRANT_GCLOUD_PERMISSIONS_LABEL = "Grant P0 permissions";

export const GCLOUD_COMPONENT_SETUP = {
  "access-logs": {
    logging: {
      filter: `(
  log_id("cloudaudit.googleapis.com/data_access")
    OR log_id("cloudaudit.googleapis.com/activity")
)
AND protoPayload.authenticationInfo.principalEmail !~ "^system"`,
      sinkId: "p0-audit-log-sink",
    } as const,
    customRole: {
      id: "p0AccessLogCollector",
      name: "P0 Access Logs Collector",
    } as const,
    requiredPermissions: ["logging.sinks.create", "logging.sinks.get"],
  } as const,
  "iam-write": {
    predefinedRole: "roles/iam.securityAdmin",
    customRole: { id: "p0IamAdmin", name: "P0 IAM Admin" } as const,
    requiredPermissions: [
      "bigquery.datasets.get",
      // Resource-level permissions on datasets require update because the IAM policy is on the dataset object
      "bigquery.datasets.update",
      "compute.instances.get",
      "iam.roles.create",
      "iam.roles.delete",
      "iam.roles.get",
      "iam.roles.list",
      "iam.serviceAccounts.getIamPolicy",
      "iam.serviceAccounts.setIamPolicy",
      "resourcemanager.projects.get",
      "resourcemanager.projects.getIamPolicy",
      "resourcemanager.projects.setIamPolicy",
    ] as const,
  } as const,
  "iam-assessment": {
    customRole: { id: "p0IamAuditor", name: "P0 IAM Auditor" } as const,
    orgLevelPermissions: [
      "resourcemanager.organizations.get",
      "resourcemanager.organizations.getIamPolicy",
      "resourcemanager.folders.get",
      "resourcemanager.folders.list",
      "resourcemanager.folders.getIamPolicy",
      "resourcemanager.projects.list",
    ],
    requiredPermissions: [
      "cloudasset.assets.analyzeIamPolicy",
      "cloudasset.assets.searchAllIamPolicies",
      "cloudasset.assets.searchAllResources",
      "essentialcontacts.contacts.list",
      "iam.roles.get",
      "iam.roles.list",
      "iam.serviceAccountKeys.list",
      "iam.serviceAccounts.get",
      "monitoring.timeSeries.list",
      "recommender.iamPolicyInsights.list",
      "resourcemanager.projects.get",
      "resourcemanager.projects.getIamPolicy",
    ] as const,
  } as const,
  "org-wide-policy": {
    customRole: {
      id: "p0OrgIamPolicyReader",
      name: "P0 Organization IAM Policy Reader",
    } as const,
    requiredPermissions: [
      "resourcemanager.organizations.get",
      "resourcemanager.organizations.getIamPolicy",
      "resourcemanager.folders.get",
      "resourcemanager.folders.getIamPolicy",
    ] as const,
  } as const,
} as const;

export const setupPermissions = (
  scope: "organization" | "project",
  data: (typeof GCLOUD_COMPONENT_SETUP)[keyof typeof GCLOUD_COMPONENT_SETUP]
) =>
  [
    ...("requiredPermissions" in data ? data.requiredPermissions : []),
    ...(scope === "organization" && "orgLevelPermissions" in data
      ? data.orgLevelPermissions
      : []),
  ].sort();

export const ALL_P0_GCLOUD_ROLES = Object.values(GCLOUD_COMPONENT_SETUP).map(
  (s) => ("customRole" in s ? s.customRole.id : undefined)
) as readonly string[];

export const GCLOUD_BASIC_ROLES: readonly string[] = [
  "owner",
  "editor",
  "viewer",
] as const;

export const PROJECT_TESTABLE_PERMISSIONS_TABLE =
  "project_testable_permissions";
