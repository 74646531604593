import {
  InfoCircleTwoTone,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Select } from "antd";
import Link from "antd/lib/typography/Link";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";

import { SearchInput } from "../../components/antd";
import { GraphTooltip } from "./GraphTooltip";
import { Prefix } from "./Prefix";

export interface GraphSearchProps {
  controls: {
    display: string;
    terms: string;
    show: string;
    onChangeDisplay: (value: string) => void;
    onChangeTerms: (value: string) => void;
    onChangeShow: (value: string) => void;
  };
  frozen?: {
    display?: boolean;
    terms?: string;
    show?: string;
    help?: ReactNode;
  };
  isSearching: boolean;
  searchExtra?: ReactNode;
  showOptions: { value: string; label: string }[];
}

// Can't use Typography.Text as it renders with incorrect background / font-color
// for tooltips.
const TooltipCode = styled.span`
  font-family: monospace;
  color: rgb(0, 171, 142);
`;

const GraphSearchHelp: React.FC<object> = () => (
  <div>
    <p>Anything you type here will filter displayed data:</p>
    <ul>
      <li>
        <TooltipCode>keyword</TooltipCode>&nbsp;&minus;&nbsp;Show data
        containing <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>column:</TooltipCode>&nbsp;&minus;&nbsp;Show data where{" "}
        <TooltipCode>column</TooltipCode> has content.
      </li>
      <li>
        <TooltipCode>column:keyword</TooltipCode>
        &nbsp;&minus;&nbsp;Show data data where{" "}
        <TooltipCode>column</TooltipCode> is linked to{" "}
        <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>column=keyword</TooltipCode>
        &nbsp;&minus;&nbsp;Show data data where{" "}
        <TooltipCode>column</TooltipCode> contains{" "}
        <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>!keyword</TooltipCode>&nbsp;&minus;&nbsp;Show data with at
        least one value not matching <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>column:!keyword</TooltipCode>&nbsp;&minus;&nbsp;Show data
        where <TooltipCode>column</TooltipCode> is linked to at least one value
        not matching <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>^term</TooltipCode>&nbsp;&minus;&nbsp;Removes items
        matching the <TooltipCode>term</TooltipCode> query from search results.
      </li>
    </ul>
    <p>
      For detailed help, see the{" "}
      {/* Deliberately keep referrer here; we'll use it for analytics */}
      <Link
        href="https://docs.p0.dev/iam-assessment/query-search"
        rel="noopener"
        target="docs"
      >
        query-search reference
      </Link>
      .
    </p>
  </div>
);

export const GraphSearch = ({
  controls,
  frozen,
  isSearching,
  showOptions,
}: GraphSearchProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      controls.onChangeTerms(event.target.value),
    // eslint is just wrong?
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [controls.onChangeTerms]
  );
  const frozenContent = (
    <span style={{ color: "#888", cursor: "default" }}>{frozen?.terms}</span>
  );

  useEffect(() => {
    setShowLoading(false);
  }, [controls.show]);

  const handleChangeShow = useCallback(
    (value: string) => {
      setShowLoading(true);
      controls.onChangeShow(value);
    },
    // eslint is just wrong?
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [controls.onChangeShow]
  );

  return (
    <div
      style={{
        alignItems: "baseline",
        display: "inline-flex",
        gap: "1em",
        maxWidth: "1000px",
        flexGrow: "1",
      }}
    >
      <Prefix
        prefix={
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            Show&nbsp;
          </div>
        }
        style={{ flexBasis: "12em" }}
      >
        <Select
          style={{ minWidth: "100px" }}
          disabled={!!frozen?.show}
          options={showOptions}
          onChange={handleChangeShow}
          value={controls.show}
          loading={showLoading}
        />{" "}
      </Prefix>
      <Prefix
        prefix={
          <GraphTooltip title={<GraphSearchHelp />} width="500px">
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Where&nbsp;
              <InfoCircleTwoTone />
            </div>
          </GraphTooltip>
        }
      >
        <SearchInput
          prefix={
            <>
              {isSearching ? <LoadingOutlined /> : <SearchOutlined />}
              {frozen?.display && frozen?.terms ? (
                frozen?.help ? (
                  <GraphTooltip title={frozen.help}>
                    {frozenContent}
                  </GraphTooltip>
                ) : (
                  frozenContent
                )
              ) : undefined}
            </>
          }
          onChange={onChangeInput}
          placeholder={frozen?.terms ? "" : "Enter search terms..."}
          style={{ marginBottom: "unset", minWidth: "31em" }}
          value={controls.terms}
        />
      </Prefix>
    </div>
  );
};
