import { List } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { capitalize, every, groupBy } from "lodash";
import pluralize from "pluralize";
import { useCallback, useMemo } from "react";
import {
  AssessmentScopeIntegration,
  GroupAssessmentScope,
} from "shared/types/assessment";
import { AssessmentScope, ItemAssessmentScope } from "shared/types/assessment";

import { targetLogo } from "./TargetLogo";

export const scopeLabel: Record<
  AssessmentScope["integration"],
  Partial<Record<AssessmentScope["type"], string>>
> = {
  aws: {
    project: "account",
  },
  gcloud: {
    group: "folder",
    project: "project",
  },
};

const isItemScopes = (
  scopes: AssessmentScope[]
): scopes is ItemAssessmentScope[] =>
  every(scopes, (s) => s.type === "project");
const isGroupScopes = (
  scopes: AssessmentScope[]
): scopes is GroupAssessmentScope[] => every(scopes, (s) => s.type === "group");

export const TargetsList: React.FC<{
  targets: AssessmentScope[];
}> = ({ targets }) => {
  const getTargetLogo = useCallback(
    (integration: AssessmentScopeIntegration) => targetLogo(integration),
    []
  );

  const grouped = useMemo(
    () => Object.entries(groupBy(targets, "integration")),
    [targets]
  ) as [AssessmentScopeIntegration, AssessmentScope[]][];

  const renderTargetItem = useCallback(
    ([integration, items]: [AssessmentScopeIntegration, AssessmentScope[]]) => (
      <List.Item>
        <List.Item.Meta
          avatar={getTargetLogo(integration)}
          title={pluralize(
            capitalize(
              scopeLabel[integration]?.[items[0].type] ?? items[0].type
            ),
            items.length
          )}
          description={
            isItemScopes(items) ? (
              items.length > 5 ? (
                <GraphTooltip
                  title={`${items.at(0)?.id} and ${items.length - 1} more`}
                >
                  {items.map((i) => i.id).join(", ")}
                </GraphTooltip>
              ) : (
                items.map((i) => i.id).join(", ")
              )
            ) : isGroupScopes(items) ? (
              items.map((i) => i.label ?? i.value)
            ) : null
          }
        />
      </List.Item>
    ),
    [getTargetLogo]
  );

  return <List dataSource={grouped} renderItem={renderTargetItem} />;
};
