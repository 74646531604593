import { RiskScore } from "../../../types/catalog";
import { IsEqual } from "../../../types/util";
import { widetype } from "../../../util/collections";

export const Usages = ["unused", "used", "unknown"] as const;

export type GrantCondition = {
  title?: string;
  description?: string;
  expression: string;
};

/** Uniquely identifies a single grant */
export type GrantEntry = {
  condition: GrantCondition | undefined;
  createdAt?: number;
  isAncestorGrant: boolean;
  lastUsedAt?: number;
  permissionSet: string;
  principal: string;
  principalType: PrincipalType;
  resources: string[];
};

export type PrincipalType =
  | "domain"
  | "federated-identity"
  | "group"
  | "iam-group"
  | "iam-user"
  | "logged-in"
  | "public"
  | "service-account"
  | "unknown"
  | "user";

export type MfaStatus = "disabled" | "enabled" | "unknown";

export type TrustedPrincipals = { trustPolicy: string; isConditional: boolean };

export type Principal = {
  directory?: string;
  disabled?: boolean;
  /** If true, this principal is managed in a separate scope
   *
   * E.g. cross-project GCP service accounts.
   */
  external?: boolean;
  isProviderManaged: boolean;
  label: string;
  mfa?: MfaStatus;
  /** This principal's parent resource
   *
   * Applies to service identities (GCP service acocunts, AWS IAM roles).
   */
  parent?: string;
  principalType: PrincipalType;
  trustedPrincipals?: TrustedPrincipals;
};

export type CredentialType = "federated" | "key" | "short-lived";

type Credential = {
  type: CredentialType;
  source: "azure-ad" | "csp" | "okta" | "workspace";
  createdTime?: number;
  status: "disabled" | "enabled" | undefined;
  lastAuthnTime: number;
};

type CredentialConsumerBase = {
  label: string;
  lastUsedTime: number;
  usageCount: number;
  principalType: PrincipalType;
  isProviderManaged: boolean;
};

export type CredentialIpConsumer = CredentialConsumerBase & {
  type: "ip";
  organization?: string;
  userAgents?: string[];
};

export type CredentialPrincipalConsumer = CredentialConsumerBase & {
  type: "principal";
};
export type CredentialConsumer =
  | CredentialIpConsumer
  | CredentialPrincipalConsumer;

export type Resource = {
  /** The resource's (short) identifier */
  id: string;
  /** The full resource locator */
  locator: string;
  /** The resource's service */
  service: string;
  /** undefined if this is a service node */
  type: string | undefined;
};

export type Grant = {
  actions?: string[];
  condition: GrantCondition | undefined;
  disabled?: boolean;
  isAncestorGrant: boolean;
  isProviderManaged: boolean;
  permissionSet: string;
  principal: string;
  principalType: PrincipalType;
  /** Grant resource patterns
   *
   * In AWS, this can be any arn pattern.
   * In GCP, this is always a length 1 explicit grant name.
   */
  resources: string[];
  trustedPrincipals?: TrustedPrincipals;
  type?: "direct" | "rollup";
};

export type Usage = (typeof Usages)[number];

export type AssessmentNodes = {
  grant: Grant;
  condition: GrantCondition | undefined;
  credential: Credential;
  consumer: CredentialConsumer;
  lateral: { flow: "accessee" | "accessor"; type: "grant" | "resource" };
  permission: object;
  permissionSet: object;
  usage: { type: Usage };
  principal: Principal;
  resource: Resource;
  risk: { name: string; score: RiskScore };
};

export const AssessmentSchema = {
  grant: [
    "actions",
    "condition",
    "disabled",
    "isAncestorGrant",
    "isProviderManaged",
    "permissionSet",
    "principal",
    "principalType",
    "resources",
    "type",
    "trustedPrincipals",
  ] as const,
  condition: ["description", "expression", "title"] as const,
  credential: [
    "type",
    "source",
    "createdTime",
    "status",
    "lastAuthnTime",
  ] as const,
  consumer: [
    //organization and userAgents not included here
    "type",
    "label",
    "lastUsedTime",
    "usageCount",
    "principalType",
    "isProviderManaged",
  ] as const,
  lateral: ["flow", "type"] as const,
  permission: [] as const,
  permissionSet: [] as const,
  usage: ["type"] as const,
  principal: [
    "directory",
    "disabled",
    "external",
    "isProviderManaged",
    "label",
    "mfa",
    "parent",
    "principalType",
    "trustedPrincipals",
  ] as const,
  resource: ["id", "locator", "service", "type"] as const,
  risk: ["name", "score"] as const,
} as const;
type AssessmentKey = keyof typeof AssessmentSchema;

const _validateAssessmentKeys: IsEqual<AssessmentKey, keyof AssessmentNodes> =
  true;
const _validateAssessmentSchema: {
  [K in keyof AssessmentNodes]: IsEqual<
    (typeof AssessmentSchema)[K][number],
    keyof NonNullable<AssessmentNodes[K]>
  >;
} = widetype.mapValues(AssessmentSchema, () => true as const);
