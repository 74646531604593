import { Reducers } from "../../graph/types";
import { AssessmentNodes } from "../../types/assessment/data";
import { CredentialAggregates } from "../../types/assessment/data/credential";
import { grantAggregate, keyAggregate, permissionAggregate } from "./shared";

export const credentialReducers: Reducers<
  AssessmentNodes,
  CredentialAggregates
> = {
  permissions: permissionAggregate,
  grants: grantAggregate,
  principal: keyAggregate("principal"),
  risks: keyAggregate("risk"),
  consumers: keyAggregate("consumer"),
};
