import { AggregatedNode } from "../../../graph/aggregate";
import { ConnectedNode } from "../../../graph/types";
import { isa } from "../../is";
import { AssessmentNodes } from "./base";
import { CredentialAggregates } from "./credential";
import { GrantAggregates } from "./grant";
import { PrincipalAggregates } from "./principal";
import { ResourceAggregates } from "./resource";

export type { GrantAggregates, GrantNode } from "./grant";
export type { PrincipalAggregates, PrincipalNode } from "./principal";
export type { ResourceAggregates, ResourceNode } from "./resource";

export type {
  AssessmentNodes,
  Grant,
  GrantCondition,
  GrantEntry,
  MfaStatus,
  Principal,
  PrincipalType,
  TrustedPrincipals,
  Usage,
} from "./base";
export { Usages } from "./base";

export type NodeFor<K extends keyof AssessmentNodes> = ConnectedNode<
  AssessmentNodes,
  K
>;

export type AnyAggregates =
  | CredentialAggregates
  | GrantAggregates
  | PrincipalAggregates
  | ResourceAggregates;

export const TargetNodeTypes = [
  "credential",
  "grant",
  "principal",
  "resource",
] as const;
export type TargetNodeType = (typeof TargetNodeTypes)[number];
export const isTargetNodeType = (
  node: NodeFor<keyof AssessmentNodes>
): node is NodeFor<TargetNodeType> => isa(TargetNodeTypes, node.type);

export type AnyNode = AggregatedNode<
  AssessmentNodes,
  TargetNodeType,
  AnyAggregates
>;

export type AuthenticationEvents = {
  account: string;
  key?: string;
  startTime: number;
  endTime: number;
  lastAuthnTime: number;
  numAuthEvents: number;
};
