import { List, Pagination, Segmented } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { RightAlignedDiv } from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useCallback, useMemo, useState } from "react";
import { assessmentParse } from "shared/assessment/issues/presets";
import { DiscoverMatch, discoverPaths } from "shared/graph/discover";
import { ConnectedNode, DirectedGraph } from "shared/graph/types";
import { AssessmentNodes, PrincipalNode } from "shared/types/assessment/data";
import { staticError } from "utils/console";

import { PrincipalMemberDisplay } from "../../cells/Principal";

const MAX_MEMBER_PAGE_SIZE = 10;

type ResultPath = DiscoverMatch<DirectedGraph<AssessmentNodes>>[];
type Result = {
  allMembers: ResultPath;
  direct: ResultPath;
  indirect: ResultPath;
};

export const GroupMembers: React.FC<{ node: PrincipalNode }> = ({ node }) => {
  const [membershipType, setMembershipType] = useState<number | string>("all");
  const [memberIndex, setMemberIndex] = useState(1);
  const [result, setResult] = useState<Result>();

  useGuardedEffect(
    async (cancellation) => {
      const searchResult = (
        await discoverPaths(
          {
            nodes: node.parents as ConnectedNode<
              AssessmentNodes,
              keyof AssessmentNodes
            >[],
          },
          (_n) => true,
          assessmentParse(`principal:${node.key}`)
        )
      )
        .filter((n) => n.node.key !== node.key)
        .map((n) => {
          //iterate all matches and find min and max paths across all matches
          const minPathLength = Math.min(
            ...n.matches.map((m) => Math.min(...m.paths.map((p) => p.length)))
          );
          const maxPathLength = Math.max(
            ...n.matches.map((m) => Math.max(...m.paths.map((p) => p.length)))
          );
          return {
            ...n,
            minPathLength,
            maxPathLength,
          };
        });
      // direct memberships represent all nodes with a minPathLength of 2 source node -> direct member
      // indirect memberships represent all nodes with a maxPathLength > 2 source node -> indirect member
      cancellation.guard(setResult)({
        allMembers: searchResult,
        direct: searchResult.filter((n) => n.minPathLength === 2),
        indirect: searchResult.filter((n) => n.maxPathLength > 2),
      });
    },
    staticError,
    [node]
  );

  const memberOptions = useMemo(
    () => [
      {
        label: `All (${result?.allMembers.length ?? 0})`,
        value: "all",
      } as const,
      {
        label: `Direct (${result?.direct.length ?? 0})`,
        value: "direct",
      } as const,
      {
        label: `Indirect (${result?.indirect.length ?? 0})`,
        value: "indirect",
      } as const,
    ],
    [result]
  );

  const members = useMemo(() => {
    if (!result) return [];
    setMemberIndex(1);
    if (membershipType === "all") return result.allMembers;
    if (membershipType === "direct") return result.direct;
    if (membershipType === "indirect") return result.indirect;
  }, [result, membershipType]);

  const renderMemberItem = useCallback(
    (item: DiscoverMatch<DirectedGraph<AssessmentNodes>>) => {
      if (!item || item.node.type !== "principal") return null;
      return (
        <List.Item key={item.node.key}>
          <div style={{ display: "flex", gap: "1em" }}>
            <PrincipalMemberDisplay
              data-testid={item.node.key}
              item={item}
              membershipType={membershipType}
            />
          </div>
        </List.Item>
      );
    },
    [membershipType]
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.7em",
          alignItems: "flex-start",
        }}
        data-testid="group-members"
      >
        {node.data.trustedPrincipals?.isConditional && (
          <p>
            ⚠️ This principal has a conditional trust policy. There may be
            additional constraints on which members can access it.
          </p>
        )}

        <div style={{ display: "flex", gap: "1em" }}>
          <GraphTooltip title="Membership">
            <Segmented
              options={memberOptions}
              value={membershipType}
              onChange={setMembershipType}
            />
          </GraphTooltip>
        </div>
        <List
          dataSource={members?.slice(
            (memberIndex - 1) * MAX_MEMBER_PAGE_SIZE,
            memberIndex + MAX_MEMBER_PAGE_SIZE - 1
          )}
          renderItem={renderMemberItem}
        />
        <RightAlignedDiv>
          <Pagination
            hideOnSinglePage={true}
            current={memberIndex}
            total={members?.length ?? 0}
            pageSize={MAX_MEMBER_PAGE_SIZE}
            showSizeChanger={false}
            onChange={setMemberIndex}
          />
        </RightAlignedDiv>
      </div>
    </>
  );
};
