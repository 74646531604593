import { Space, Typography } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { useContext, useMemo } from "react";
import { Node } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data/base";

import {
  ResourceInfo,
  awsResourceInfo,
  gcpResourceInfo,
  resourceLabels,
} from "../node/resource/labels";
import { HasAddTerm, ShowHideTerm } from "./ShowHide";

export const Resource: React.FC<
  Partial<HasAddTerm> & {
    resource: Node<AssessmentNodes, "resource"> | string;
    shortPath?: boolean;
  }
> = ({ resource, shortPath, terms, onAddTerm }) => {
  const { integration } = useContext(ScopeContext);

  const info = useMemo(
    () =>
      typeof resource === "string"
        ? integration === "aws"
          ? awsResourceInfo(resource, shortPath)
          : integration === "gcloud"
          ? gcpResourceInfo(resource, shortPath)
          : ({} as ResourceInfo)
        : resourceLabels(resource.data, shortPath),
    [integration, resource, shortPath]
  );

  const icon = useMemo(
    () => info?.url && <img src={info.url} width={14} height={14} />,
    [info?.url]
  );

  return info ? (
    <GraphTooltip
      title={
        <Space direction="vertical">
          <div>
            {icon}&nbsp;{info.service}
            {info.type ? `\xa0${info.type}` : ""}
          </div>
          <div>{info.isService ? "Entire service" : info.id}</div>
          <div>
            <Typography.Text code>{info.locator}</Typography.Text>
          </div>
          {onAddTerm && (
            <ShowHideTerm
              term={`${info.isGeneric ? "grant=resource:" : "resource="}"${
                info.locator
              }"`}
              name="resources"
              onAddTerm={onAddTerm}
              terms={terms}
            />
          )}
        </Space>
      }
      width="400px"
    >
      {icon}&nbsp;{info.id}
    </GraphTooltip>
  ) : null;
};
