import { InfoCircleTwoTone } from "@ant-design/icons";
import { Spin, Switch, message } from "antd";
import React, { useCallback, useState } from "react";

import { useGuardedEffect } from "../../hooks/useGuardedEffect";
import { CodeUpload } from "../CodeUpload/CodeUpload";
import { useAuthFetch } from "../Login/hook";
import { CONFIG_URL } from "./config";

export const SnowflakeRestStateConfigEditor: React.FC<object> = () => {
  const [initialValue, setInitialValue] = useState("");
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [actualConfig, setActualConfig] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const setSingleError = useCallback(
    (error: string | undefined) => setErrors(error ? [error] : []),
    [setErrors]
  );
  const authFetch = useAuthFetch(setSingleError);
  useGuardedEffect(
    async (cancellation) => {
      try {
        setLoadingConfig(true);
        const response = await authFetch(
          !actualConfig ? CONFIG_URL : `${CONFIG_URL}/actual`,
          {
            method: "GET",
            onNotOk: async (response) => {
              if (response.status !== 400) return;
              const data = await response.json();
              cancellation.guard(setErrors)(
                data.errorState.errors?.map(
                  (e: any) => `${e.error} at line ${e.start.line}`
                ) ?? [data.error] ?? [String(data)]
              );
            },
          }
        );
        if (!response) return;
        const data = await response.json();
        cancellation.guard(setInitialValue)(JSON.stringify(data, null, 4));
      } finally {
        cancellation.guard(setLoadingConfig)(false);
      }
    },
    setSingleError,
    [authFetch, actualConfig]
  );
  const handleConfigSubmit = useCallback(
    async (value: string) => {
      const res = await authFetch(CONFIG_URL, {
        method: "POST",
        body: value,
        headers: { "Content-Type": "application/json" },
      });
      if (!res) return;
      setErrors([]);
      message.success("Snowflake configuration successfully saved.");
    },
    [authFetch]
  );

  return (
    <>
      <p>
        You can upload your rest state configuration below, and you can then
        manage drift/run a remediation job underneath the editor.
      </p>
      {loadingConfig ? (
        <Spin />
      ) : (
        <CodeUpload
          errors={errors}
          instructions={
            <>
              {/* @TODO: Add full docs when they're ready (see Routing page). */}

              <p>
                <Switch
                  defaultChecked={actualConfig}
                  // Legacy do not copy
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(checked: boolean) => setActualConfig(checked)}
                />{" "}
                Actual Configuration
              </p>
              <p>
                If you check this box, the current configuration will be
                computed from your Snowflake instance. Otherwise, you can upload
                a custom configuration.
              </p>
              <p>
                <InfoCircleTwoTone /> Maximum configuration size should not
                exceed 1 MB.
              </p>
              <p>
                Use this editor to build your rest state configuration for
                Snowflake. This configuration can be used to define an expected
                state in your Snowflake instance and enforce that state should
                any deviations occur (such as someone granting someone else
                additional access).
              </p>
              <p>
                There are two top-level components to this config:
                &quot;managedObjects&quot; and &quot;state&quot;.
              </p>
              <h4>Managed Objects:</h4>
              <p>
                Define which objects will be monitored by this config:
                databases, roles, warehouses, and users. You can use
                &quot;*&quot; for any of these options (except users) in order
                to apply this config to all.
              </p>
              <h4>State:</h4>
              <p>
                This also has two properties: &quot;entitlements&quot; and
                &quot;privileges&quot;.
              </p>
              <p>
                Entitlements refer to the roles that users or other roles have
                access to.
              </p>
              <p>
                Privileges refer to what exact access to a database, schema,
                table, view, materialized view, warehouse, or account that a
                particular role or user should have.
              </p>
              <p>Reach out to us directly for help at support@p0.dev.</p>
            </>
          }
          defaultValue={initialValue}
          onSubmit={handleConfigSubmit}
        />
      )}
    </>
  );
};
