import { Element, InstallSpec, ItemComponent } from "../../../install/types";

const iamAssessmentDescription =
  "Allows P0 to analyze your IAM configuration for security issues.";

const GCLOUD_PROJECT_PATTERN = /^[A-Za-z0-9-]+$/;
const GCLOUD_ORGANIZATION_PATTERN = /^[0-9]+$/;

// https://cloud.google.com/run/docs/triggering/https-request#deterministic
const GCLOUD_CLOUD_RUN_URL_PATTERN = /^https:\/\/[a-zA-Z0-9-.]+\.run\.app$/;

const gcloudProjectValidator = async (id: string, _field: string) => {
  if (!id.match(GCLOUD_PROJECT_PATTERN))
    return "Google Cloud project identifiers must consist of alphanumeric characters and hyphens only";
};

const gcloudOrganizationValidator = async (_id: string, field: string) => {
  if (!field.match(GCLOUD_ORGANIZATION_PATTERN))
    return "Google Cloud organization identifiers must consist of numerals only";
};

const gcloudProjectFieldValidator = async (_id: string, field: string) => {
  if (!field.match(GCLOUD_PROJECT_PATTERN))
    return "Google Cloud project identifiers must consist of alphanumeric characters and hyphens only";
};

const gcloudCloudRunUrlValidator = async (_id: string, field: string) => {
  if (!field.match(GCLOUD_CLOUD_RUN_URL_PATTERN))
    return "Cloud Run URL must be in the format https://<service name>.run.app";
};

export const GcloudComponents = InstallSpec({
  "access-logs": ItemComponent({
    label: "Access logging",
    description:
      "Enhance IAM assessments with continuous usage data from your projects.",
    type: "dynamic",
    schema: {
      "pre-20240729-pubsub-ingest": Element({
        type: "hidden",
        label: "Legacy Pub/Sub ingest table name",
      }),
    },
  }),
  "iam-assessment": ItemComponent({
    label: "IAM assessment",
    description: iamAssessmentDescription,
    type: "string",
    schema: {},
    validator: gcloudProjectValidator,
  }),
  "iam-write": ItemComponent({
    label: "IAM management",
    description:
      "Allows P0 to grant and revoke Google Cloud access for your users.",
    type: "string",
    schema: {},
    validator: gcloudProjectValidator,
  }),
  "org-wide-policy": ItemComponent({
    label: "Organization policies",
    description:
      "Allows P0 to analyze your organization and folder IAM policies for security issues.",
    type: "singleton",
    schema: {},
  }),
  "sharing-restriction": ItemComponent({
    label: "Domain-restricted sharing",
    description: "Prevent privilege escalation via P0's integration.",
    type: "dynamic",
    schema: {},
  }),
  "org-access-logs": ItemComponent({
    label: "Organization access logging",
    description:
      "Enhance IAM assessments with continuous usage data from your entire organization.",
    type: "singleton",
    schema: {
      "pre-20240729-pubsub-ingest": Element({
        type: "hidden",
        label: "Legacy Pub/Sub ingest table name",
      }),
    },
  }),
  "org-iam-assessment": ItemComponent({
    label: "Organization IAM assessment",
    description: `${iamAssessmentDescription} Installed for every project in your organization.`,
    type: "singleton",
    schema: {},
  }),
  root: ItemComponent({
    label: "Google Cloud organization",
    type: "singleton",
    schema: {
      organizationId: Element({
        label: "Organization ID",
        type: "string",
        validator: gcloudOrganizationValidator,
      }),
      serviceAccountEmail: Element({
        label: "Service account",
        type: "hidden",
      }),
      impersonateViaEmail: Element({
        label: "Intermediate service account",
        description:
          "If present, service account to impersonate prior to impersonating serviceAccountEmail",
        type: "hidden",
      }),
    },
  }),
  "iam-write-security-perimeter": ItemComponent({
    label: "IAM management security perimeter",
    description:
      "Provides a secure mechanism for P0 to make changes to your projects' IAM configurations.",
    type: "singleton",
    schema: {
      projectId: Element({
        label: "Project identifier",
        description:
          "The Google Cloud project identifier where the security perimeter will be installed.",
        type: "string",
        step: "new",
        validator: gcloudProjectFieldValidator,
      }),
      allowedDomains: Element({
        label: "Allowed domains",
        description:
          "Only users with these email domains may be granted access to your projects",
        type: "generated",
      }),
      cloudRunUrl: Element({
        label: "Security perimeter URL",
        description:
          "The URL of the Cloud Run service that enforces the security perimeter.",
        type: "string",
        validator: gcloudCloudRunUrlValidator,
      }),
    },
    featureFlag: "gcloudSecurityPerimeter",
  }),
});
