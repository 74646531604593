// TODO: Make shared a sub-module so we can guarantee this package
import stringify from "json-stable-stringify";
import { size } from "lodash";

import { ConnectedNode, DirectedGraph, Direction, Node } from "./types";

type Dictionary<T> = Record<string, T>;

type DirectedEdge<G extends object> = {
  parent: ConnectedNode<G, keyof G>;
  child: ConnectedNode<G, keyof G>;
};

/** Internal utility for deduplicating nodes */
export const keyOf = <G extends object>(node: Node<G, keyof G>) =>
  `${String(node.type)}:${node.key}`;

/** Cycle-avoiding DFS graph traversal algorithm
 *
 * Visits every node in the graph.
 */
export const dfs = <G extends object, T>(
  graph: DirectedGraph<G>,
  direction: Direction,
  config: {
    init: (node: ConnectedNode<G, keyof G>) => T;
    combine: (left: T, right: T) => T;
    finalize?: (node: ConnectedNode<G, keyof G>, memo: T) => void;
  }
): Record<string, T> => {
  const { init, combine, finalize } = config;
  const visited = new Set<string>();
  const cache: Dictionary<T> = {};
  function traverse(node: ConnectedNode<G, keyof G>): T {
    const key = keyOf(node);
    if (cache[key]) {
      return cache[key];
    }
    let memo = init(node);
    for (const sub of node[direction]) {
      const subKey = keyOf(sub);
      // Two scenarios in which we've already visited this node:
      // 1. We've already visited in this traversal (cycle)
      // 2. We visited this node in a previous traversal (cached result)
      // Only skip in scenario 1, otherwise descend, which returns the cached value
      if (!cache[subKey] && visited.has(subKey)) continue;
      visited.add(subKey);
      memo = combine(memo, traverse(sub));
    }
    finalize?.(node, memo);
    cache[key] = memo;
    return memo;
  }
  const output: Record<string, T> = {};
  for (const node of graph.nodes) {
    output[keyOf(node)] = traverse(node);
  }
  return output;
};

/** Creates a directed graph by adding nodes and edges
 *
 * Automatically handles duplicates. The built graph may be cyclic.
 */
export class GraphBuilder<G extends object> {
  #nodes: Dictionary<ConnectedNode<G, keyof G>> = {};
  #edges: Dictionary<DirectedEdge<G>> = {};

  add<L extends keyof G>(
    type: L,
    item: G[L] & { key: string }
  ): ConnectedNode<G, L> {
    if (typeof type === "string" && type.includes(":")) {
      throw new Error("Node type may not include ':' symbol");
    }
    if (typeof item.key !== "string") {
      throw Object.assign(new Error("Node key is not a string"), {
        type,
        item,
      });
    }
    const dictKey = `${String(type)}:${item.key}`;
    const { key, ...data } = item;
    if (this.#nodes[dictKey] === undefined) {
      this.#nodes[dictKey] = {
        type,
        key,
        data: data as G[L],
        children: [],
        parents: [],
      };
    }
    return this.#nodes[dictKey] as ConnectedNode<G, L>;
  }

  lookup<L extends keyof G>(
    type: L,
    key: string
  ): ConnectedNode<G, L> | undefined {
    const dictKey = `${String(type)}:${key}`;
    return this.#nodes[dictKey] as ConnectedNode<G, L>;
  }

  connect(parent: ConnectedNode<G, keyof G>, child: ConnectedNode<G, keyof G>) {
    const key = stringify([parent.type, parent.key, child.type, child.key]);
    if (this.#edges[key] === undefined) {
      this.#edges[key] = { parent, child };
    }
    return this;
  }

  build(): DirectedGraph<G> {
    for (const edge of Object.values(this.#edges)) {
      edge.parent.children.push(edge.child);
      edge.child.parents.push(edge.parent);
    }

    return { nodes: Object.values(this.#nodes) };
  }

  size() {
    return {
      nodes: size(this.#nodes),
      edges: size(this.#edges),
    };
  }
}
