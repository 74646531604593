import {
  AwsSshSessionCommandArgs,
  AzureSshSessionCommandArgs,
  GcpSshSessionCommandArgs,
  SshGroupRequestCommandArgs,
  SshSessionCommandArgs,
} from "./types";

export const isAwsArn = (destination: string) =>
  destination.startsWith("arn:aws:");
export const isGcloudResourceUrl = (destination: string) =>
  destination.startsWith("//compute.googleapis.com");
export const isAzureInstanceId = (destination: string) =>
  destination.startsWith("/subscriptions/");

const VALID_GCLOUD_PROVIDERS = ["gcloud", "gcp"];

const normalizeProvider = (provider: string | undefined) => {
  return provider?.toLowerCase();
};

export const isAwsGroupCommandArgs = (
  args: SshGroupRequestCommandArgs
): args is AwsSshSessionCommandArgs =>
  normalizeProvider(args.provider) === "aws" || (args as any).account;

export const isGcloudGroupCommandArgs = (
  args: SshGroupRequestCommandArgs
): args is GcpSshSessionCommandArgs =>
  (args.provider &&
    VALID_GCLOUD_PROVIDERS.includes(normalizeProvider(args.provider) ?? "")) ||
  (args as any).project;

export const isAwsId = (id: string) => id.match(/^aws:/);
export const isGcloudId = (id: string) => id.match(/^gcloud:/);

export const isAwsSshCommandArgs = (
  args: SshSessionCommandArgs
): args is AwsSshSessionCommandArgs =>
  isAwsArn(args.destination) ||
  normalizeProvider(args.provider) === "aws" ||
  (args as any).account;

export const isGcpSshCommandArgs = (
  args: SshSessionCommandArgs
): args is GcpSshSessionCommandArgs =>
  isGcloudResourceUrl(args.destination) ||
  (args.provider &&
    VALID_GCLOUD_PROVIDERS.includes(normalizeProvider(args.provider) ?? "")) ||
  (args as any).project;

export const isAzureSshCommandArgs = (
  args: SshSessionCommandArgs
): args is AzureSshSessionCommandArgs =>
  isAzureInstanceId(args.destination) ||
  args.provider === "azure" ||
  (args as any).subscriptionId;
