import { Radio, RadioChangeEvent, Select, Space, Spin, Typography } from "antd";
import { ErrorDisplay } from "components/Error";
import { useAuthFetch } from "components/Login/hook";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { capitalize, compact, sortBy } from "lodash";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  ControllerProps,
  ControllerRenderProps,
  UseFormStateReturn,
} from "react-hook-form";
import {
  AssessmentScope,
  AssessmentScopeIntegration,
  isGroupAssessmentScope,
  isItemAssessmentScope,
} from "shared/types/assessment";
import { mapWith } from "shared/util/collections";
import { assertNever } from "utils/assert";

import { scopeLabel } from "./Targets";

const { Text } = Typography;

export type TargetInput = {
  targets: {
    type: AssessmentScope["type"];
    scopes: AssessmentScope[];
    subtractiveScopes: AssessmentScope[];
  };
};

export type TargetSelectProps = {
  control: Control<TargetInput>;
  integration: AssessmentScope["integration"];
};

type ScopeOption = {
  value: string;
  label?: string;
  type: AssessmentScope["type"];
};

const toOption = (scope: AssessmentScope): ScopeOption | undefined =>
  scope.type === "organization"
    ? undefined
    : scope.type === "group"
    ? { value: scope.value, label: scope.label, type: scope.type }
    : scope.type === "project"
    ? { value: scope.id, label: scope.label, type: scope.type }
    : assertNever(scope);

const fromOption =
  (integration: AssessmentScopeIntegration, type: AssessmentScope["type"]) =>
  (option: ScopeOption): AssessmentScope => {
    const base = { integration, label: option.label };
    // TODO: remove GCP-specific hard-coding
    return type === "organization"
      ? { ...base, type }
      : type === "group"
      ? { ...base, type, key: "folder", value: option.value }
      : type === "project"
      ? { ...base, type, id: option.value }
      : assertNever(type);
  };

const TargetInput = ({
  integration,
  field,
  state,
}: {
  integration: AssessmentScope["integration"];
  field: ControllerRenderProps<TargetInput, "targets">;
  state: UseFormStateReturn<TargetInput>;
}) => {
  const [available, setAvailable] = useState<AssessmentScope[]>();
  const [error, setError] = useState<string>();
  const authFetch = useAuthFetch(setError);

  const value = field.value ?? {
    type: "item",
    scopes: [],
    subtractiveScopes: [],
  };

  useEffect(() => {
    setAvailable(undefined);
  }, [integration]);

  useGuardedEffect(
    async (cancellation) => {
      const response = await authFetch(
        `assessment/_meta/integrations/${integration}/scopes`,
        { method: "GET" }
      );
      if (!response) return;
      const { scopes } = await response.json();
      if (cancellation.isCancelled) return;
      setAvailable(scopes);
    },
    setError,
    [authFetch, integration]
  );

  const radioOptions = useMemo(() => {
    const options = [{ value: "organization", label: "Organization" }];
    if (available?.find(isGroupAssessmentScope)) {
      options.push({
        value: "group",
        label: pluralize(capitalize(scopeLabel[integration].group)) ?? "Groups",
      });
    }
    if (available?.find(isItemAssessmentScope)) {
      options.push({
        value: "project",
        label:
          pluralize(capitalize(scopeLabel[integration].project)) ?? "Items",
      });
    }
    return options;
  }, [available, integration]);

  const selectOptions = useMemo(
    () =>
      sortBy(
        compact(
          (available ?? []).filter((a) => a.type === value.type).map(toOption)
        ),
        (o) => o.label ?? o.value
      ),
    [available, value.type]
  );

  const subtractSelectOptions = useMemo(
    () =>
      sortBy(
        compact(
          mapWith(available ?? [], function* (a) {
            if (value.type !== "project" && a.type !== value.type) {
              yield toOption(a);
            }
          })
        ),
        (o) => o.label ?? o.value
      ),
    [available, value.type]
  );
  const selectedValues = useMemo(
    () => compact(value.scopes.map(toOption)).map((s) => s.value),
    [value.scopes]
  );
  const selectedSubtractiveValues = useMemo(
    () => compact(value.subtractiveScopes.map(toOption)).map((s) => s.value),
    [value.subtractiveScopes]
  );

  const onChangeRadio = useCallback(
    (event: RadioChangeEvent) =>
      field.onChange({
        type: event.target.value,
        scopes: [],
        subtractiveScopes: [],
      }),
    [field]
  );

  const onChangeItems = useCallback(
    (key: "scopes" | "subtractiveScopes") =>
      (_: string[], options: ScopeOption | ScopeOption[]) => {
        const currentTarget = field.value;

        const scopes: AssessmentScope[] = [options]
          .flat()
          .map((s) => fromOption(integration, s.type)(s));
        field.onChange({ ...currentTarget, [key]: scopes });
      },
    [field, integration]
  );
  const onChangeScopes = useMemo(
    () => onChangeItems("scopes"),
    [onChangeItems]
  );
  const onChangeSubtractiveScopes = useMemo(
    () => onChangeItems("subtractiveScopes"),
    [onChangeItems]
  );

  return !available && !error ? (
    <Spin />
  ) : (
    <Space
      direction="vertical"
      size="small"
      style={{ width: "100%", marginBottom: "1em" }}
    >
      {error && (
        <ErrorDisplay
          title="Could not load available assessment targets"
          error={error}
        />
      )}
      <Radio.Group
        options={radioOptions}
        optionType="button"
        buttonStyle="solid"
        value={value.type}
        onChange={onChangeRadio}
      />
      {
        // Default to project select to encourage lower-burden assessments
        value.type !== "organization" && (
          <Select<string[], ScopeOption>
            defaultValue={[]}
            mode="multiple"
            onChange={onChangeScopes}
            options={selectOptions}
            placeholder={`Select ${pluralize(
              scopeLabel[integration][value.type] ?? capitalize(value.type)
            )}`}
            style={{ width: "100%" }}
            value={selectedValues}
          />
        )
      }
      {value.type !== "project" && (
        <>
          Excluding these targets: <Text type="secondary">(optional)</Text>
          <Select<string[], ScopeOption>
            defaultValue={[]}
            mode="multiple"
            onChange={onChangeSubtractiveScopes}
            options={subtractSelectOptions}
            placeholder={
              value.type === "organization"
                ? `Select ${pluralize(
                    scopeLabel[integration]["project"] ?? capitalize("project")
                  )} and ${pluralize(
                    scopeLabel[integration]["group"] ?? capitalize("group")
                  )} to exclude`
                : `Select ${pluralize(
                    scopeLabel[integration]["project"] ?? capitalize("project")
                  )} to exclude`
            }
            style={{ width: "100%" }}
            value={selectedSubtractiveValues}
          />
        </>
      )}
      {state.errors.targets && (
        <span style={{ color: "red" }} role="alert">
          A selection is required.
        </span>
      )}
    </Space>
  );
};

export const TargetSelect = ({
  control,
  integration,
}: TargetSelectProps): React.ReactElement => {
  const renderTargets = useCallback<
    ControllerProps<TargetInput, "targets">["render"]
  >(
    ({ field, formState }) => (
      <TargetInput field={field} state={formState} integration={integration} />
    ),
    [integration]
  );
  return (
    <>
      <Typography.Paragraph style={{ marginBottom: "6px" }}>
        What should P0 assess?
      </Typography.Paragraph>
      <Controller
        name="targets"
        rules={{ required: true }}
        control={control}
        render={renderTargets}
      />
    </>
  );
};
