import { Descriptions } from "antd";
import { Typography } from "antd";
import {
  LastAuthenticated,
  authnHelp,
} from "components/Assessment/components/cells/LastAuthenticated";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { CommandDisplay } from "components/Integrations/CommandDisplay";
import pluralize from "pluralize";
import { useContext } from "react";
import {
  credentialsByStaleness,
  credentialsByUsage,
} from "shared/assessment/reducers/principal";
import {
  AssessmentNodes,
  PrincipalAggregates,
  PrincipalNode,
  PrincipalType,
  TrustedPrincipals,
} from "shared/types/assessment/data";

import { ConsumersList } from "../../cells/ConsumersList";
import {
  CredentialList,
  serviceAccountInfoFromKey,
} from "../../cells/Credential";
import { RiskGroupedPermissionList } from "../../cells/PermissionAggregate";
import { PrincipalExternalLink, PrincipalLink } from "../../cells/Principal";
import { Resource } from "../../cells/Resource";
import { NodeDescriptions } from "../NodeDescriptions";
import { GroupMembers } from "./GroupMember";

const { Item } = Descriptions;

const hasMembers = (principalType: PrincipalType) =>
  principalType === "group" || principalType === "federated-identity";
export const TrustPolicyDisplay: React.FC<{
  trustedPrincipals: TrustedPrincipals;
}> = ({ trustedPrincipals }) => (
  <div>
    <Typography style={{ fontWeight: 700 }}>Trust Policy:</Typography>
    <CommandDisplay
      hideCopy
      commands={JSON.stringify(
        JSON.parse(trustedPrincipals.trustPolicy),
        null,
        2
      )}
    />
  </div>
);

/* 
We must map each of usedCredentials.used, usedCredentials.unused, staleCredentials.stale, staleCredentials.fresh to
extract only the data part and the additional service account key information. The non-data part (parents, children) may
lead to circular references when exporting to JSON. Also flattens the data so it's easy to answer questions like
"used" and "stale" credentials.
*/
export const credentialExportFromPrincipal = (node: PrincipalNode) => {
  const credentialMap = new Map<
    string,
    AssessmentNodes["credential"] & { used?: boolean; stale?: boolean }
  >();

  const updateCredential = (
    key: string,
    data: AssessmentNodes["credential"] & {
      used?: boolean;
      stale?: boolean;
    }
  ) => {
    const credential = credentialMap.get(key);
    if (credential) {
      credentialMap.set(key, { ...credential, ...data });
    } else {
      credentialMap.set(key, data);
    }
  };
  const credentialsUsage = credentialsByUsage(
    node.aggregates.credentials ?? []
  );
  const credentialsStaleness = credentialsByStaleness(
    node.aggregates.credentials ?? []
  );
  credentialsUsage.used.forEach((cred) =>
    updateCredential(cred.id, { used: true, ...cred })
  );
  credentialsUsage.unused.forEach((cred) =>
    updateCredential(cred.id, { used: false, ...cred })
  );
  credentialsStaleness.stale.forEach((cred) =>
    updateCredential(cred.id, { stale: true, ...cred })
  );
  credentialsStaleness.fresh.forEach((cred) =>
    updateCredential(cred.id, { stale: false, ...cred })
  );
  return Array.from(credentialMap.entries()).map(([key, credential]) => {
    return {
      ...serviceAccountInfoFromKey(key),
      ...credential,
    };
  });
};

const principalConsumers = (
  node: PrincipalNode,
  credentials: PrincipalAggregates["credentials"]
) =>
  node.data.principalType === "service-account"
    ? credentials.find((c) => c.type === "short-lived")
    : undefined;

export const PrincipalNodeDisplay: React.FC<{ node: PrincipalNode }> = ({
  node,
}) => {
  const { integration } = useContext(ScopeContext);
  const grantCount = node.aggregates.grants.length;
  const credentials = node.aggregates.credentials;

  const impersonationInfo = principalConsumers(node, credentials);
  const impersonationConsumers = node.aggregates.consumers.filter(
    (consumer) => consumer.type === "principal"
  );
  return (
    <>
      <NodeDescriptions>
        <Item label="Principal">
          <PrincipalExternalLink data={node.data} id={node.key} />
        </Item>
        {node.data.parent && (
          <Item label="Parent">
            <Resource resource={node.data.parent} />
          </Item>
        )}
        <Item label="Last Used">
          <LastAuthenticated
            principal={node.data}
            last={node.aggregates.lastAuthEvent}
          />
        </Item>
        {impersonationInfo && (
          <Item label="Used By">
            {impersonationConsumers ? (
              <ConsumersList consumers={impersonationConsumers} />
            ) : impersonationInfo.lastAuthnTime === 0 ? (
              "None"
            ) : (
              "Unknown"
            )}
          </Item>
        )}
        {node.data.principalType === "user" && (
          <Item label="MFA">
            {node.data.mfa || (
              <GraphTooltip title={authnHelp["user"]}>Unknown</GraphTooltip>
            )}
          </Item>
        )}
        {(node.data.principalType === "user" ||
          (node.data.principalType === "service-account" &&
            !node.data.isProviderManaged)) && (
          <Item label="Authentication Methods">
            <CredentialList
              principalType={node.data.principalType}
              credentials={node.aggregates.credentials}
            />
          </Item>
        )}
        {hasMembers(node.data.principalType) && (
          <Item label="Members">
            <GroupMembers node={node} />
          </Item>
        )}
        <Item label="Grants">
          <Typography.Paragraph>
            This principal can exercise {grantCount}{" "}
            {pluralize("grant", grantCount)}
          </Typography.Paragraph>
          <PrincipalLink data={node.data} show="principal" />
        </Item>
        {integration && (
          <Item label="Risks">
            <RiskGroupedPermissionList
              permissions={node.aggregates.permissions}
              integration={integration}
              showControl
            />
          </Item>
        )}
      </NodeDescriptions>
      {node.data.trustedPrincipals ? (
        <TrustPolicyDisplay
          trustedPrincipals={node.data.trustedPrincipals}
        ></TrustPolicyDisplay>
      ) : null}
    </>
  );
};
