import { ConfigOf } from "../../../install/types";
import { PermissionSpec, UnstagedPermission } from "../../../types/permission";
import { AwsResourceGenerated } from "../aws/accesses";
import { SessionManagerGenerated } from "../aws/types";
import { GcloudResourceParent } from "../gcloud/types";
import { SshComponents } from "./components";

export const AWS_TAG_KEY_REGEX = /^[a-zA-Z0-9+-=.,_:@]{0,128}$/;

// https://cloud.google.com/resource-manager/docs/tags/tags-creating-and-managing#creating_tag
export const GCLOUD_TAG_KEY_REGEX = /^(([A-Za-z0-9-])+\/[^'"\\/]{1,256})?$/;

export type ProvisionUserAccessParameters = {
  UserName: string[];
  Action: string[];
  RequestId: string[];
  Sudo?: string[];
  PublicKey?: string[];
};

export type SshSpec = SshAll | SshGroupSpec | SshSessionSpec;
export type SshSessionSpec =
  | SshAwsSessionSpec
  | SshAzureSessionSpec
  | SshGcloudSessionSpec;

export type SshGcloudSessionSpec = PermissionSpec<
  "ssh",
  {
    type: "session";
    spec: GcloudNodeSpec;
  },
  // TODO @ENG-2258: perform ssm grants without the aws permissioner - share the ssh object between aws and gcp
  GcloudResourceParent["generated"]
>;

export type SshAwsSessionSpec = PermissionSpec<
  "ssh",
  {
    type: "session";
    spec: AwsSshResourceSpec;
  },
  AwsResourceGenerated & SessionManagerGenerated
>;

export type SshGroupSpec =
  | AwsSshGroupSpec
  | AzureSshGroupSpec
  | GcloudSshGroupSpec;

type BaseSshGroupSpec = {
  type: SshProviderKey;
  sudo?: boolean;
  key: string;
  name: string;
};

export type AwsSshGroupSpec = PermissionSpec<
  "ssh",
  {
    type: "group";
    spec: {
      type: typeof AWS_PROVIDER;
      accountAlias?: string;
      accountId?: string;
    } & BaseSshGroupSpec;
  },
  AwsResourceGenerated & SessionManagerGenerated
>;

export type AzureSshGroupSpec = PermissionSpec<
  "ssh",
  {
    type: "group";
    spec: {
      type: typeof AZURE_PROVIDER;
      subscriptionId: string;
      subscriptionName: string;
    } & BaseSshGroupSpec;
  },
  AwsResourceGenerated & SessionManagerGenerated
>;

export type SshAzureSessionSpec = PermissionSpec<
  "ssh",
  {
    type: "session";
    spec: AzureNodeSpec;
  },
  object //TODO: @ENG-2551 Filled in with AzureResourceGenerated when permissioner is implemented
>;

export type GcloudSshGroupSpec = PermissionSpec<
  "ssh",
  {
    type: "group";
    spec: {
      type: typeof GCLOUD_PROVIDER;
      projectId?: string;
    } & BaseSshGroupSpec;
  },
  GcloudResourceParent["generated"]
>;

export type SshAll = PermissionSpec<
  "ssh",
  {
    type: "all";
    sudo: boolean;
  },
  object
>;

export type AwsSshResourceSpec = {
  type: typeof AWS_PROVIDER;
  accountId: string;
  instanceId?: string;
  instanceName?: string;
  accountAlias?: string; // account may not have an alias
  region: string;
  sudo?: boolean;
  publicKey?: string;
  // Legacy from pre-lifecycle AWS permission model; it's copied here
  awsResourcePermission: {
    permission: {
      type: "resource";
      accountId: string;
      idcArn?: string;
      idcId?: string;
      idcRegion?: string;
      policies: string[];
      service: string;
      object?: string;
      draftTemporaryPolicy?: any;
      resource: {
        type: "arn";
        arn: string;
        resourceTag?: {
          key: string;
          name: string;
        };
      };
      userName: string;
      basePermissionSetArn?: string;
      basePermissionSetName?: string;
    };
  };
};

export type GcloudNodeSpec = {
  type: typeof GCLOUD_PROVIDER;
  fullName: string; // format: //compute.googleapis.com/projects/{projectId}/zones/{zone}/instances/{instanceName}
  projectId: string;
  zone: string;
  instanceName: string;
  sudo?: boolean;
  publicKey: string;
  gcloudResourcePermission: UnstagedPermission<GcloudResourceParent>;
};

export type AzureNodeSpec = {
  type: typeof AZURE_PROVIDER;
  subscriptionId: string;
  subscriptionName: string;
  resourceGroupId: string;
  instanceId: string;
  name: string;
  region: string;
  networkInterfaceIds: string[];
  tags: Record<string, string>;
  sudo?: boolean;
};

export type SshNodeSpec = AwsSshResourceSpec | AzureNodeSpec | GcloudNodeSpec;

export const isGcloudSshPermission = (
  data: SshSessionSpec
): data is SshGcloudSessionSpec =>
  data.permission.spec.type === GCLOUD_PROVIDER;

export const isAwsSshSessionPermission = (
  data: SshSessionSpec
): data is SshAwsSessionSpec => data.permission.spec.type === AWS_PROVIDER;

export const isAzureSshSessionPermission = (
  data: SshSessionSpec
): data is SshAzureSessionSpec => data.permission.spec.type === AZURE_PROVIDER;

type BaseSshSessionCommandArgs = {
  destination: string;
  "public-key": string;
  sudo?: boolean;
};

export type AwsSshSessionCommandArgs = {
  provider?: typeof AWS_PROVIDER;
  account?: string;
  region?: string;
  group?: string;
} & BaseSshSessionCommandArgs;

export type GcpSshSessionCommandArgs = {
  provider?: typeof GCLOUD_PROVIDER;
  project?: string;
} & BaseSshSessionCommandArgs;

export type AzureSshSessionCommandArgs = {
  provider?: typeof AZURE_PROVIDER;
  subscription?: string;
} & BaseSshSessionCommandArgs;

export type UnknownSshSessionCommandArgs = {
  provider: undefined;
} & BaseSshSessionCommandArgs;

export type SshSessionCommandArgs =
  | AwsSshSessionCommandArgs
  | AzureSshSessionCommandArgs
  | GcpSshSessionCommandArgs
  | UnknownSshSessionCommandArgs;

export type GcpSshGroupRequestCommandArgs = {
  project?: string;
} & BaseSshGroupRequestCommandArgs;

export type AwsSshGroupRequestCommandArgs = {
  account?: string;
} & BaseSshGroupRequestCommandArgs;

export type BaseSshGroupRequestCommandArgs = {
  provider?: SshProviderKey;
  name?: string;
  destination?: string;
  sudo?: boolean;
};

export type SshGroupRequestCommandArgs = AwsSshGroupRequestCommandArgs &
  GcpSshGroupRequestCommandArgs;

export type SshAllCommandArgs = {
  provider?: SshProviderKey;
  account?: string;
  project?: string;
  sudo?: boolean;
};

export type SshComponents = typeof SshComponents;

export type SshUse = keyof SshComponents;

export type SshIntegration = ConfigOf<typeof SshComponents>;

export const AWS_PROVIDER = "aws";
export const GCLOUD_PROVIDER = "gcloud";
export const AZURE_PROVIDER = "azure";

export const SshProviders = [
  AWS_PROVIDER,
  AZURE_PROVIDER,
  GCLOUD_PROVIDER,
] as const;
export type SshProviderKey = (typeof SshProviders)[number];

// TODO: ENG-2629 remove this and use SshProviders once azure is fully supported
export const OldSshProviders = [AWS_PROVIDER, GCLOUD_PROVIDER] as const;
