import { List, Segmented, Typography } from "antd";
import Link from "antd/lib/typography/Link";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { useCallback, useMemo, useState } from "react";
import {
  credentialsByStaleness,
  credentialsByUsage,
} from "shared/assessment/reducers/principal";
import {
  AssessmentNodes,
  PrincipalAggregates,
  PrincipalType,
} from "shared/types/assessment/data";
import { assertNever } from "utils/assert";

import { ClipDiv } from "../../../divs";
import { CountAggregate } from "./Aggregate";
import { NodeLink } from "./NodeLink";
import { HasAddTerm, ShowHideTerm } from "./ShowHide";

export const serviceAccountInfoFromKey = (credentialKey: string) => {
  const [projectId, _, serviceAccountId, _itemType, keyId] = credentialKey
    .split("/")
    .slice(4);
  return {
    projectId,
    serviceAccountId,
    keyId,
  };
};

export const UsedCredentialAggregate = ({
  credentials,
  onAddTerm,
  terms,
}: {
  credentials: Record<string, PrincipalAggregates["credentials"]>;
} & HasAddTerm) => {
  return CountAggregate({
    getSearchTerm: (val) => `authentication:"${val}"`,
    inputMap: credentials,
    typeOptions: ["used", "unused"],
    onAddTerm,
    terms,
    termName: "credentials",
  });
};

export const StaleCredentialAggregate = ({
  credentials,
  onAddTerm,
  terms,
}: {
  credentials: Record<string, PrincipalAggregates["credentials"]>;
} & HasAddTerm) => {
  return CountAggregate({
    getSearchTerm: (val) =>
      val === "stale" ? "credential:stale90:true" : "credential:stale90:false",
    inputMap: credentials,
    typeOptions: ["stale", "fresh"],
    onAddTerm,
    terms,
    termName: "credentials",
  });
};

export const CredentialDisplay: React.FC<
  Partial<HasAddTerm> & {
    credential: AssessmentNodes["credential"];
    id: string;
  }
> = ({ credential, id, terms, onAddTerm }) => {
  if (!id) return null;
  const { projectId, serviceAccountId, keyId } = serviceAccountInfoFromKey(id);
  const keyComponent = (
    <>
      Key&nbsp;
      <Link
        href={`https://console.cloud.google.com/iam-admin/serviceaccounts/details/${serviceAccountId}/keys?project=${projectId}`}
        target="_blank"
        rel="noopener"
      >
        {keyId}
      </Link>
    </>
  );
  return (
    <GraphTooltip
      title={
        <>
          {credential.type === "key" && credential.status === "disabled" ? (
            "This key has been disabled"
          ) : credential.type === "short-lived" ? (
            <Typography.Paragraph>
              Short-lived credentials represent all ephemeral login methods,
              including JWT tokens generated using{" "}
              <Link
                href="https://cloud.google.com/iam/docs/create-short-lived-credentials-direct"
                target="_blank"
                rel="noopener"
              >
                direct token creation
              </Link>
              , service-account impersonation, and service-account usage from
              Google-managed services.
            </Typography.Paragraph>
          ) : credential.type === "key" ? (
            "Static credential"
          ) : credential.type === "federated" ? (
            "Federated access"
          ) : (
            assertNever(credential.type)
          )}
          {terms !== undefined && onAddTerm && (
            <>
              <ShowHideTerm
                term={`credential=type:"${credential.type}"`}
                name="credential types"
                terms={terms}
                onAddTerm={onAddTerm}
              />
            </>
          )}
        </>
      }
    >
      {credential.type === "key" ? (
        keyComponent
      ) : credential.type === "federated" ? (
        <>Federated login</>
      ) : credential.type === "short-lived" ? (
        <>Short-lived credentials</>
      ) : (
        assertNever(credential.type)
      )}
    </GraphTooltip>
  );
};

export const CredentialList: React.FC<{
  credentials: PrincipalAggregates["credentials"];
  principalType: PrincipalType;
}> = ({ credentials, principalType }) => {
  const [usedSelected, setUsedSelected] = useState<number | string>("unused");
  const credentialsUsage = credentialsByUsage(credentials);
  const credentialsStaleness = credentialsByStaleness(credentials);
  const allCredentials: PrincipalAggregates["credentials"] = useMemo(
    () => [
      ...(credentialsUsage["used"] ?? []),
      ...(credentialsUsage["unused"] ?? []),
    ],
    [credentialsUsage]
  );
  const usedOptions = useMemo(
    () => [
      {
        label: `Unused (${credentialsUsage.unused?.length ?? 0})`,
        value: "unused",
      } as const,
      {
        label: `Used (${credentialsUsage.used?.length ?? 0})`,
        value: "used",
      } as const,

      {
        label: `All (${credentials.length})`,
        value: "all",
      } as const,
    ],
    [credentialsUsage, credentials]
  );

  const [staleSelected, setStaleSelected] = useState<number | string>("all");

  const used: string[] = useMemo(() => {
    const selected =
      usedSelected === "all"
        ? allCredentials
        : credentialsUsage[usedSelected as keyof typeof credentialsUsage] ?? [];
    return selected.map((n) => n.id);
  }, [allCredentials, credentialsUsage, usedSelected]);
  const stale = useMemo(() => {
    const selected =
      staleSelected === "all"
        ? allCredentials
        : credentialsStaleness[
            staleSelected as keyof typeof credentialsStaleness
          ] ?? [];
    return selected.map((n) => n.id);
  }, [allCredentials, credentialsStaleness, staleSelected]);

  const staleOptions = useMemo(() => {
    const credCount = (type: "fresh" | "stale") =>
      used.filter(
        (x) => !!credentialsStaleness?.[type]?.find((n) => n.id === x)
      ).length;

    return [
      {
        label: `Stale (${credCount("stale")})`,
        value: "stale",
      } as const,
      {
        label: `Fresh (${credCount("fresh")})`,
        value: "fresh",
      } as const,
      {
        label: `All (${used.length})`,
        value: "all",
      } as const,
    ];
  }, [used, credentialsStaleness]);

  const shown = useMemo(() => {
    return stale.filter((x) => !!used.find((n) => n === x));
  }, [stale, used]);

  return principalType === "service-account" || principalType === "user" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.7em",
        alignItems: "flex-start",
      }}
    >
      <div style={{ display: "flex", gap: "1em" }}>
        <GraphTooltip title="Unused credentials have not been used in the past 40 days">
          <Segmented
            options={usedOptions}
            value={usedSelected}
            onChange={setUsedSelected}
          />
        </GraphTooltip>

        {principalType === "service-account" && (
          <GraphTooltip title="Stale credentials have not been rotated in the past 90 days">
            <Segmented
              options={staleOptions}
              value={staleSelected}
              onChange={setStaleSelected}
            />
          </GraphTooltip>
        )}
      </div>
      <CredentialInnerList shown={shown} credentials={credentials} />
    </div>
  ) : null;
};

const CredentialInnerList: React.FC<{
  shown: string[];
  credentials: PrincipalAggregates["credentials"];
}> = ({ shown, credentials }) => {
  const renderItem = useCallback(
    (item: string) => {
      const credential = credentials.find((n) => n.id === item);
      if (!credential) return null;
      const credentialNode = {
        type: "credential" as const,
        key: credential.id,
        data: credential,
      };
      return (
        <List.Item key={item}>
          <ClipDiv>
            <NodeLink node={credentialNode}></NodeLink>
            &nbsp;
            <CredentialDisplay credential={credential} id={item} />
          </ClipDiv>
        </List.Item>
      );
    },
    [credentials]
  );
  return <List dataSource={shown} renderItem={renderItem} />;
};
